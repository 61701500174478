import { useEffect, useRef, useState } from 'react';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { debounce } from 'loadsh';
import { FacebookShareButton, TwitterShareButton } from 'react-share';
import { ToastContainer, toast, Slide } from 'react-toastify';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { isMobile } from 'react-device-detect';

const fadeText = {
  fadeIn: { opacity: 1, y: 0, transition: { duration: 1 } },
  hidden: { opacity: 0, y: 20 },
};

const fadeBtn = {
  fadeIn: { opacity: 1, scale: 1, transition: { duration: 1 } },
  hidden: { opacity: 0, scale: 1.2 },
};

function Animate({ children, variants, delay = 0 }) {
  const control = useAnimation();
  const [ref, inView] = useInView({ threshold: 0 });

  useEffect(() => {
    if (inView) {
      setTimeout(() => control.start('fadeIn'), delay);
    } else {
      control.start('hidden');
    }
  }, [control, delay, inView]);

  return (
    <motion.div ref={ref} variants={variants} initial="hidden" animate={control}>
      {children}
    </motion.div>
  );
}

function scrollTo(hash) {
  document.location.hash = '#' + hash;
}

function Section({ id, children, src, onFocus }) {
  const [ref, inView] = useInView({ threshold: 0.5 });
  useEffect(() => {
    if (onFocus && inView) {
      onFocus(id);
    }
  }, [id, inView, onFocus]);

  return (
    <div
      id={id}
      ref={ref}
      className="relative h-screen w-screen bg-cover bg-center bg-no-repeat snap-center text-white"
    >
      {children}
    </div>
  );
}

function Cookies() {
  const [popup, setPopup] = useState(false);
  const [hbg, setHbg] = useState(false);
  const [click, setClick] = useState(false);
  const [nav, setNav] = useState(0);
  const shareRef = useRef(null);
  const [w, setWidth] = useState(window.innerWidth);
  const [h, setHeight] = useState(window.innerHeight * 0.01);

  const notify = () => {
    toast('복사가 완료되었습니다.', {
      position: 'bottom-center',
      autoClose: 2000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: true,
      progress: undefined,
      theme: 'dark',
      transition: Slide,
    });
    toast.clearWaitingQueue();
  };

  const handleToggleClick = (e) => setClick((prev) => !prev);

  const handleResize = debounce(() => {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight * 0.01);
    if (w > h * 100) {
      setHbg(false);
    }
    document.documentElement.style.setProperty('--h', `${h}px`);
  }, 300);

  const [touchPosition, setTouchPosition] = useState();
  const [yPosition, setYposition] = useState(window.pageYOffset);
  const [isMoving, setIsMoving] = useState(false);

  const touchStart = (e) => {
    setTouchPosition({
      x: e.changedTouches[0].pageX,
      y: e.changedTouches[0].pageY,
    });
  };

  const touchMove = (e) => {
    const distanceX = Math.abs(touchPosition.x - e.changedTouches[0].pageX);
    const distanceY = Math.abs(touchPosition.y - e.changedTouches[0].pageY);

    if (isMoving) {
      return;
    }

    if (distanceY + distanceX > 1 && distanceX < distanceY) {
      if (touchPosition.y - e.changedTouches[0].pageY < 0) {
        setYposition(Math.min(0, yPosition + distanceY));
      } else if (touchPosition.y - e.changedTouches[0].pageY > 0) {
        const max = h * 100 * -4;
        setYposition(Math.max(max, yPosition - distanceY));
      }
    }
    setIsMoving(true);
  };

  const touchEnd = () => {
    setIsMoving(false);
  };

  const removeEvent = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  useEffect(() => {
    handleResize();

    const handleClickOutSide = (e) => {
      if (shareRef.current && !shareRef.current.contains(e.target)) {
        setClick(false);
      }
    };

    const navBtn = document.getElementsByName('navBtn');

    window.addEventListener('resize', handleResize);
    document.addEventListener('mousedown', handleClickOutSide);
    // isMobile && document.addEventListener('touchmove', removeEvent, { passive: false });
    // isMobile && navBtn.forEach((element) => element.addEventListener('click', removeEvent, { passive: false }));

    return () => {
      window.removeEventListener('resize', handleResize);
      document.removeEventListener('mousedown', handleClickOutSide);
      // isMobile && document.removeEventListener('touchmove', removeEvent);
      // isMobile && navBtn.forEach((element) => element.removeEventListener('click', removeEvent));
    };
  }, [handleResize, shareRef]);

  return (
    <div className="home hero bg-[#343636]" onTouchStart={null} onTouchMove={null} onTouchEnd={null}>
      <ToastContainer limit={1} />

      <div className="hero-overlay bg-opacity-50 absolute none z-0" />
      <div
        id="fullPage"
        className="fullPage bg-center"
        style={{
          backgroundImage: `url("${'.././bg-section04.webp'}")`,
          height: 'calc(var(--h, 1vh) * 100)',
          backgroundAttachment: 'fixed',
        }}
      >
        {/* Navigation Bar */}
        <nav
          className={`stroke blurGlass w-screen fixed overflow-hidden flex flex-row justify-between items-start text-center top-0 z-40 text-base fadeIn fast transitionFastest ${
            hbg === true ? 'h-[60vh]' : 'h-[8vh]'
          }`}
        >
          {/* Navigation Left */}
          <div
            className={`flex justify-start items-center h-[8vh] pt-1 pl-[1vw] ${w < h * 100 ? 'w-[50vw]' : 'w-[25vw]'}`}
          >
            {/* Hamburger */}
            {w <= h * 100 && (
              <div className="hidden flex-col items-center relative w-[6vh] h-[8vh]">
                <button
                  className={`hamburger w-[3vh] h-full absolute ${
                    hbg === true ? 'opacity-0 -z-10 -rotate-45' : 'opacity-100 z-10 rotate-0'
                  }`}
                  onClick={() => setHbg(true)}
                />
                <button
                  className={`navUp w-[3vh] h-full absolute ${
                    hbg === true ? 'opacity-100 z-10 rotate-0' : 'opacity-0 -z-10 rotate-90'
                  }`}
                  onClick={() => setHbg(false)}
                />
              </div>
            )}

            {/* Logo */}
            <button
              name="navBtn"
              className="flex flex-col justify-center ml-[1vw] h-[8vh]"
              // onClick={() => scrollTo(0)}
              // onTouchStart={() => setYposition(0)}
            >
              <a href="/" className="w-[20vh]">
                <img src=".././logo+text-landscape.svg" alt="logo" className="w-[20vh]" />
              </a>
            </button>
          </div>

          {/* Navigation Center */}
          <ul
            className={`w-[50vw] h-[8vh] pt-1 flex justify-center text-white ${
              w <= h * 100 &&
              `slideDown transitionFastest flex-col items-center mt-[8vh] ${
                hbg ? ' opacity-100 translate-y-[20vh] z-20' : 'opacity-0 translate-y-0 -z-10'
              }`
            }`}
          >
            <li className={`navBtn ${w < h * 100 && 'm-[2vh]'}`}>
              <button
                name="navBtn"
                className={`font-medium flex justify-center items-center w-[12vh] h-[6vh] mx-6 ${
                  nav === '1' && 'active'
                }`}
                // onClick={() => scrollTo(1)}
                // onTouchStart={() => setYposition(0 - h * 100)}
              >
                쿠키 정책
              </button>
            </li>
          </ul>

          {/* Navigation Right */}
          <ul
            className={`flex flex-row pt-1 pr-[1vw] items-center justify-end h-[8vh] ${
              w < h * 100 ? 'w-[50vw]' : 'w-[25vw]'
            }`}
          >
            {/* Youtube Button */}
            <li className="flex flex-col items-center w-[6vh]">
              <a
                href="https://www.youtube.com/channel/UCGyXcENDHN_C3POdU-BiZXg?sub_confirmation=1"
                target="_blank"
                rel="noreferrer"
              >
                <div className="youtube mx-[1.5vh] w-[3vh] h-[8vh] p-0" />
              </a>
            </li>
            {/* Twitter Button */}
            <li className="flex flex-col items-center w-[6vh]">
              <a
                href="https://twitter.com/intent/follow?original_referer=https%3A%2F%2Flistella.tv%2F&ref_src=twsrc%5Etfw%7Ctwcamp%5Ebuttonembed%7Ctwterm%5Efollow%7Ctwgr%5Elistella_on&screen_name=listella_on"
                target="_blank"
                rel="noreferrer"
              >
                <div className="twitter mx-[1.5vh] w-[3vh] h-[6vh] p-0" />
              </a>
            </li>

            {/* Share Button */}
            <li className="flex flex-col items-center w-[6vh]">
              <button className="share flex w-[3vh] h-[8vh] p-0" onClick={() => handleToggleClick()}></button>
            </li>
          </ul>
        </nav>

        {/* Share Box */}
        <div
          ref={shareRef}
          className={`share-box z-40 absolute flex flex-col justify-center right-0 mt-[9vh] w-[8vh]  ${
            click ? 'scale-100 h-[28vh]' : 'scale-0 h-[56vh]'
          }`}
        >
          <ul className="flex justify-center items-center m-1 division">
            <FacebookShareButton url={'https://listella.tv/'}>
              <div className="facebook w-[3vh] h-[8vh] p-0" />
            </FacebookShareButton>
          </ul>
          <ul className="flex justify-center items-center m-1 division">
            <TwitterShareButton
              url={'버츄얼 라이버 모집 오디션 진행중!\n#리스텔라 #버추얼라이버모집\nhttps://listella.tv/'}
            >
              <div className="twitter w-[3vh] h-[8vh] p-0" />
            </TwitterShareButton>
          </ul>
          <ul className="flex justify-center items-center m-1">
            <CopyToClipboard text={'버츄얼 라이버 모집 오디션 진행중!\nhttps://listella.tv/'} onCopy={notify}>
              <button>
                <div className="link w-[3vh] h-[8vh] p-0" />
              </button>
            </CopyToClipboard>
          </ul>
        </div>

        {/* Sections */}

        <div
          className="z-0 justify-center flex flex-col text-center text-neutral-content p-0 w-screen max-w-full transitionFastest"
          style={{ transform: `translate3d(0, ${yPosition}px, 0)` }}
        >
          {/* Section1 */}
          <Section id="1" onFocus={(id) => setNav(id)}>
            <div className="section-content">
              <Animate variants={fadeText} delay={300}>
                <h3 className={`${w < h * 100 ? 'my-[6vh]' : 'my-[8vh]'}`}>쿠키 정책</h3>
              </Animate>
              <div className="w-screen justify-center items-center flex">
                <div
                  className="flex w-[45vh] sm:w-[100vh] text-[1.7vh] text-left -space-y-1"
                  style={{ lineHeight: '3.8vh' }}
                >
                  <Animate variants={fadeText} delay={600}>
                    <div className="content-cookies">
                      <p style={{ whiteSpace: 'pre-wrap' }}>최신 업데이트 시간: 2022년 12월 6일</p>
                      <p style={{ whiteSpace: 'pre-wrap', minHeight: '1.5em' }}></p>
                      <p style={{ whiteSpace: 'pre-wrap' }}>
                        저희는 쿠키, 로그 파일, 픽셀 태그, 웹 비콘, 스크립트, eTags 디바이스 id와 유사한 파일 혹은
                        기술(이하 통칭 "쿠키")로 사용자의 디바이스와 본 웹사이트 이용 정보를 수집 및 저장합니다.
                        아래에서 더 자세한 쿠키 관련 정보와 제어 정보를 확인하실 수 있습니다.
                      </p>
                      <p style={{ whiteSpace: 'pre-wrap', minHeight: '1.5em' }}></p>
                      <p style={{ whiteSpace: 'pre-wrap' }}>
                        <strong>1. 쿠키란?</strong>
                      </p>
                      <p style={{ whiteSpace: 'pre-wrap' }}>
                        쿠키는 일종의 텍스트 파일로 소량의 정보가 포함되어 있으며, 특정 웹사이트 방문 시, 사용자의
                        브라우저 또는 디바이스에 다운로드됩니다. 그 후 매번 방문 때마다, 쿠키는 원본 웹사이트에
                        전송되거나, 해당 쿠키를 식별하는 또 다른 웹사이트로 전송됩니다. 쿠키는 웹사이트 운영과 보다
                        효율적인 작업 그리고 웹사이트 이용 상황 정보 제공에 널리 사용되고 있습니다.
                      </p>
                      <p style={{ whiteSpace: 'pre-wrap' }}>
                        쿠키는 다양한 기능이 있습니다. 예를 들어 사용자에게 빠른 로딩, 선호도 기록 및 전반적인 체험
                        개선을 제공합니다. (예: 쿠키는 저희에게 사용자의 사이트 첫 방문 여부를 확인해 줍니다.)
                      </p>
                      <p style={{ whiteSpace: 'pre-wrap', minHeight: '1.5em' }}>&nbsp;</p>
                      <p style={{ whiteSpace: 'pre-wrap' }}>
                        <strong>2. 어떤 유형의 쿠키를 사용하고 어떻게 사용되나요?</strong>
                      </p>
                      <p style={{ whiteSpace: 'pre-wrap' }}>
                        본 웹사이트에서는 성능, 분석, 추적 쿠키를 사용합니다. 자세한 리스트:
                      </p>
                      <div className="table-cookies">
                        <table style={{ minWidth: '198px' }} className="w-[45vh] sm:w-[100vh]">
                          <colgroup>
                            <col className="w-[74px] sm:w-[190px]" />
                            <col className="w-[74px] sm:w-[190px]"/>
                            <col className="w-[74px] sm:w-[190px]" />
                            <col className="w-[56px] sm:w-[123px]" />
                          </colgroup>
                          <tbody>
                            <tr>
                              <td style={{ backgroundColor: '#ffffff15' }}>
                                <p style={{ whiteSpace: 'pre-wrap', textAlign: 'center' }}>쿠키 명칭</p>
                              </td>
                              <td style={{ backgroundColor: '#ffffff15' }}>
                                <p style={{ whiteSpace: 'pre-wrap', textAlign: 'center' }}>용도</p>
                              </td>
                              <td style={{ backgroundColor: '#ffffff15' }}>
                                <p style={{ whiteSpace: 'pre-wrap', textAlign: 'center' }}>사용 기한</p>
                              </td>
                              <td data-colwidth="123" style={{ backgroundColor: '#ffffff15' }}>
                                <p style={{ whiteSpace: 'pre-wrap', textAlign: 'center' }}>제1자/제3자</p>
                              </td>
                            </tr>
                            <tr>
                              <td style={{ backgroundColor: 'transparent' }}>
                                <p style={{ whiteSpace: 'pre-wrap', textAlign: 'center' }}>_gid</p>
                              </td>
                              <td rowspan="2" style={{ backgroundColor: 'transparent' }}>
                                <p style={{ whiteSpace: 'pre-wrap', textAlign: 'center' }}>구글 웹사이트 데이터 분석</p>
                              </td>
                              <td style={{ backgroundColor: 'transparent' }}>
                                <p style={{ whiteSpace: 'pre-wrap', textAlign: 'center' }}>1일</p>
                              </td>
                              <td rowspan="2" data-colwidth="123" style={{ backgroundColor: 'transparent' }}>
                                <p style={{ whiteSpace: 'pre-wrap', textAlign: 'center' }}>제3자</p>
                              </td>
                            </tr>
                            <tr>
                              <td style={{ backgroundColor: 'transparent' }}>
                                <p style={{ whiteSpace: 'pre-wrap', textAlign: 'center' }}>_ga</p>
                              </td>
                              <td style={{ backgroundColor: 'transparent' }}>
                                <p style={{ whiteSpace: 'pre-wrap', textAlign: 'center' }}>2년</p>
                              </td>
                            </tr>
                            {/* <tr>
                              <td style={{ backgroundColor: 'transparent' }}>
                                <p style={{ whiteSpace: 'pre-wrap', textAlign: 'center' }}>account_id</p>
                              </td>
                              <td rowspan="3" style={{ backgroundColor: 'transparent' }}>
                                <p style={{ whiteSpace: 'pre-wrap', textAlign: 'center' }}>
                                  로그인 상태 인증 및 사용자의 로그인 상태 보호
                                </p>
                              </td>
                              <td style={{ backgroundColor: 'transparent' }}>
                                <p style={{ whiteSpace: 'pre-wrap', textAlign: 'center' }}>1년</p>
                              </td>
                              <td rowspan="3" data-colwidth="123" style={{ backgroundColor: 'transparent' }}>
                                <p style={{ whiteSpace: 'pre-wrap', textAlign: 'center' }}>제1자</p>
                              </td>
                            </tr>
                            <tr>
                              <td style={{ backgroundColor: 'transparent' }}>
                                <p style={{ whiteSpace: 'pre-wrap', textAlign: 'center' }}>cookie_token</p>
                              </td>
                              <td style={{ backgroundColor: 'transparent' }}>
                                <p style={{ whiteSpace: 'pre-wrap', textAlign: 'center' }}>48시간</p>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <p style={{ whiteSpace: 'pre-wrap', textAlign: 'center' }}>ltoken</p>
                              </td>
                              <td style={{ backgroundColor: 'transparent' }}>
                                <p style={{ whiteSpace: 'pre-wrap', textAlign: 'center' }}>1년</p>
                              </td>
                            </tr>
                            <tr>
                              <td style={{ backgroundColor: 'transparent' }}>
                                <p style={{ whiteSpace: 'pre-wrap', textAlign: 'center' }}>mi18nLang</p>
                              </td>
                              <td style={{ backgroundColor: 'transparent' }}>
                                <p style={{ whiteSpace: 'pre-wrap', textAlign: 'center' }}>
                                  사용자가 주로 사용했던 언어를 기록해서 매칭
                                </p>
                              </td>
                              <td style={{ backgroundColor: 'transparent' }}>
                                <p style={{ whiteSpace: 'pre-wrap', textAlign: 'center' }}>1년</p>
                              </td>
                              <td data-colwidth="123" style={{ backgroundColor: 'transparent' }}>
                                <p style={{ whiteSpace: 'pre-wrap', textAlign: 'center' }}>제1자</p>
                              </td>
                            </tr>
                            <tr>
                              <td style={{ backgroundColor: 'transparent' }}>
                                <p style={{ whiteSpace: 'pre-wrap', textAlign: 'center' }}>_UUID</p>
                              </td>
                              <td style={{ backgroundColor: 'transparent' }}>
                                <p style={{ whiteSpace: 'pre-wrap', textAlign: 'center' }}>
                                  사용자의 작업을 기록해서 웹페이지 개선
                                </p>
                              </td>
                              <td style={{ backgroundColor: 'transparent' }}>
                                <p style={{ whiteSpace: 'pre-wrap', textAlign: 'center' }}>1년</p>
                              </td>
                              <td data-colwidth="123" style={{ backgroundColor: 'transparent' }}>
                                <p style={{ whiteSpace: 'pre-wrap', textAlign: 'center' }}>제1자</p>
                              </td>
                            </tr> */}
                          </tbody>
                        </table>
                      </div>
                      <p style={{ whiteSpace: 'pre-wrap', minHeight: '1.5em' }}></p>
                      <p style={{ whiteSpace: 'pre-wrap' }}>
                        <strong>3. 쿠키 제어 및 삭제</strong>
                      </p>
                      <p style={{ whiteSpace: 'pre-wrap' }}>
                        웹사이트 첫 방문 시, 팝업된 배너를 클릭하면, 쿠키 사용에 동의한 것으로 간주합니다.
                      </p>
                      <p style={{ whiteSpace: 'pre-wrap' }}>
                        사용자는 언제든 선호 설정을 업데이트할 수 있습니다——대다수의 디바이스(예: 모바일 애플리케이션),
                        브라우저(예: 웹 애플리케이션 및 페이지)에서 쿠키 설정을 변경할 수 있습니다. 해당 설정은 보통
                        "옵션"이나 "선호" 메뉴에 있습니다. 아래 링크는 주류 브라우저에서 쿠키를 관리하는 법을
                        소개합니다. 사용자는 브라우저 "도움" 항목에서 더 자세한 정보를 조회할 수 있습니다.
                      </p>
                      <p style={{ whiteSpace: 'pre-wrap' }}>
                        기타 브라우저와 관련된 정보를 찾으시려면 브라우저 개발자 웹사이트를 방문하세요. 만약 선호 설정
                        변경에 문제가 있으시다면 contact@listella.tv으로 메일을 보내주세요.
                      </p>
                      <ul>
                        <li>
                          <p style={{ whiteSpace: 'pre-wrap' }}>
                            <a
                              href="https://support.google.com/accounts/answer/61416"
                              data-type="a"
                              rel="noopener noreferrer nofollow"
                              target="_blank"
                            >
                              Google Chrome
                            </a>
                          </p>
                        </li>
                        <li>
                          <p style={{ whiteSpace: 'pre-wrap' }}>
                            <a
                              href="https://support.microsoft.com/en-us/windows/microsoft-edge-browsing-data-and-privacy-bb8174ba-9d73-dcf2-9b4a-c582b4e640dd"
                              data-type="a"
                              rel="noopener noreferrer nofollow"
                              target="_blank"
                            >
                              Microsoft Edge
                            </a>
                          </p>
                        </li>
                        <li>
                          <p style={{ whiteSpace: 'pre-wrap' }}>
                            <a
                              href="https://support.mozilla.org/en-US/kb/enhanced-tracking-protection-firefox-desktop"
                              data-type="a"
                              rel="noopener noreferrer nofollow"
                              target="_blank"
                            >
                              Mozilla Firefox
                            </a>
                          </p>
                        </li>
                        <li>
                          <p style={{ whiteSpace: 'pre-wrap' }}>
                            <a
                              href="https://support.microsoft.com/en-gb/windows/delete-and-manage-cookies-168dab11-0753-043d-7c16-ede5947fc64d"
                              data-type="a"
                              rel="noopener noreferrer nofollow"
                              target="_blank"
                            >
                              Microsoft Internet Explorer
                            </a>
                          </p>
                        </li>
                        <li>
                          <p style={{ whiteSpace: 'pre-wrap' }}>
                            <a
                              href="https://help.opera.com/en/latest/web-preferences/"
                              data-type="a"
                              rel="noopener noreferrer nofollow"
                              target="_blank"
                            >
                              Opera
                            </a>
                          </p>
                        </li>
                        <li>
                          <p style={{ whiteSpace: 'pre-wrap' }}>
                            <a
                              href="https://support.apple.com/en-gb/safari"
                              data-type="a"
                              rel="noopener noreferrer nofollow"
                              target="_blank"
                            >
                              Apple Safari
                            </a>
                          </p>
                        </li>
                      </ul>
                      <p style={{ whiteSpace: 'pre-wrap', minHeight: '1.5em' }}></p>
                      <p style={{ whiteSpace: 'pre-wrap' }}>
                        주의: 쿠키를 차단하면 일부 기능이나 목적이 실행되지 않을 수 있습니다. (예: 로그인 상태 확인,
                        로그인 안전 보호 등)
                      </p>
                      <p style={{ whiteSpace: 'pre-wrap', minHeight: '1.5em' }}>&nbsp;</p>
                      <p style={{ whiteSpace: 'pre-wrap' }}>
                        <strong>4. &nbsp;정책 변경</strong>
                      </p>
                      <p style={{ whiteSpace: 'pre-wrap' }}>
                        저희는 실제 상황과 서비스의 변화를 반영하기 위해 쿠키 정책을 업데이트할 예정입니다. 쿠키 정책이
                        변경되면, 쿠키 정책 맨 위에 "최신 업데이트 일자"가 수정됩니다. 저장된 쿠키 정보 수집 사용/공유
                        방식에 큰 변화가 생겼을 경우, 저희는 사용자가 로그인 혹은 서비스 이용 시, 눈에 띄는 방식으로
                        변경 알림을 배포해 알리겠습니다. 수시로 본 페이지를 방문해 쿠키 정책 혹은 기타 정책의 변경을
                        확인하는 걸 권장드립니다.
                      </p>
                      <p style={{ whiteSpace: 'pre-wrap', minHeight: '1.5em' }}>&nbsp;</p>
                      <p style={{ whiteSpace: 'pre-wrap' }}>
                        <strong>5. 과거 설정 쿠키</strong>
                      </p>
                      <p style={{ whiteSpace: 'pre-wrap' }}>
                        한 개 혹은 다수의 쿠키를 차단해도 차단 전의 쿠키 정보는 수집할 수 있습니다. 하지만, 차단 후의
                        쿠키 정보는 수집하지 않습니다.
                      </p>
                      <p style={{ whiteSpace: 'pre-wrap', minHeight: '1.5em' }}>&nbsp;</p>
                      <p style={{ whiteSpace: 'pre-wrap' }}>
                        <strong>6. Contact Us</strong>
                      </p>
                      <p style={{ whiteSpace: 'pre-wrap' }}>
                        본 정책 또는 개인 정보에 관해 궁금하신 점이 있을 경우 contact@listella.tv로 메일을 보내주세요.
                      </p>
                      <p style={{ whiteSpace: 'pre-wrap', minHeight: '1.5em' }}></p>
                      <p style={{ whiteSpace: 'pre-wrap', textAlign: 'right' }}>LISTELLA</p>
                      <p style={{ whiteSpace: 'pre-wrap', textAlign: 'right' }}>시행일: 2022년 12월 6일</p>
                    </div>
                  </Animate>
                </div>
              </div>
            </div>
          </Section>
        </div>
      </div>
    </div>
  );
}

export default Cookies;
