import { Route, Routes, Navigate } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import { isMobile } from 'react-device-detect';

import Intro from './Intro';

export default function IntroRoute() {
  const [cookies] = useCookies(['cookie_token']);
  if (isMobile || cookies.cookie_token === 'true') {
    return <Navigate replace to="/home" />;
  }

  return (
    <Routes>
      <Route path="/" element={<Intro />} />
    </Routes>
  );
}
