import React, { useEffect, useState, lazy, Suspense } from 'react';
import { useCookies } from 'react-cookie';

import useStore from '../../components/Store';
const WebGL = lazy(() => import('./WebGL'));

export default function Intro() {
  const loaded = useStore((state) => state.loaded);
  const clicked = useStore((state) => state.clicked);
  const api = useStore((state) => state.api);
  const [ready, setReady] = useState(false);
  const [cookies, setCookies] = useCookies(['cookie_token']);
  const [boxClicked, setBoxClicked] = useState(false);
  const expires = new Date();

  useEffect(() => {
    const video = document.getElementById('loading');

    video.addEventListener('loadeddata', () => {
      if (video.readyState === 4) {
        video.style.display = 'block';
      }
    });

    video.addEventListener('ended', () => {
      setReady(true);
    });

    video.addEventListener('pause', () => {
      setTimeout(() => video.play(), 20);
    });
  }, []);

  const handleOnClick = () => {
    expires.setFullYear(expires.getFullYear() + 1);
    setBoxClicked(true);
    setCookies('cookie_box_token', true, {
      path: '/',
      expires,
      // secure: true,
      // httpOnly: true,
    });
  };

  return (
    <div className="introWrapper">
      {cookies.cookie_box_token !== 'true' && !boxClicked && (
        <div className="cookie-box">
          <p className="flex-1">
            더 나은 열람 환경을 위해 cookie를 사용합니다.
            <a className="ml-[10px] text-indigo-400" href="/legal/cookies" target="_blank">
              자세히 알아보기
            </a>
          </p>
          <button className="cookie-box-btn bg-slate-700" onClick={() => handleOnClick()}>
            OK
          </button>
        </div>
      )}

      <div
        className={`fullscreen ${clicked && 'fadeOut slow'}`}
        style={{
          overflowX: 'hidden',
          overflowY: 'scroll',
          msOverflowStyle: 'none',
          scrollbarWidth: 'none',
        }}
      >
        <div
          onClick={() => loaded && api.start()}
          className={`${ready && 'intro likeVideo'} ${!clicked && 'pointerActive'}`}
          style={{ backgroundImage: "url('./pv-loading.png')" }}
        />
        <div className={'loadState fadeIn slow'}>
          {!loaded || !ready ? (
            <>
              <span>loading</span>
              <br />
              <span>&nbsp;</span>
            </>
          ) : (
            <>
              <span className={'fadeIn fast'} style={{ fontSize: '50px' }}>
                LISTELLA
              </span>
              <br />
              <span className={'fadeIn fast'}>click to continue</span>
            </>
          )}
        </div>
        <video autoPlay muted playsInline className={'intro fadeIn slow'} id="loading">
          <source src="./pv-loading.mp4" type="video/mp4" />
        </video>
      </div>

      <Suspense fallback={null}>
        <WebGL />
      </Suspense>
    </div>
  );
}
