import { useEffect } from 'react';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

export const fadeText = {
  fadeIn: { opacity: 1, y: 0, transition: { duration: 1 } },
  hidden: { opacity: 0, y: 20 },
};

export const fadeBtn = {
  fadeIn: { opacity: 1, scale: 1, transition: { duration: 1 } },
  hidden: { opacity: 0, scale: 1.2 },
};

export function Animate({ children, variants, delay = 0, ...props }) {
  const control = useAnimation();
  const [ref, inView] = useInView({ threshold: 0.6 });

  useEffect(() => {
    if (inView) {
      setTimeout(() => control.start('fadeIn'), delay);
    } else {
      control.start('hidden');
    }
  }, [control, delay, inView]);

  return (
    <motion.div ref={ref} variants={variants} initial="hidden" animate={control} {...props}>
      {children}
    </motion.div>
  );
}

export function scrollTo(hash) {
  document.location.hash = '#' + hash;
}
