import useSWR from 'swr';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Autoplay } from 'swiper';
import { Section } from './Section';
import { fadeText, Animate, } from '../../lib/home/utils';



function Tweets(props) {
  const { w, h } = props;
  //트위터 포스팅 정보를 받아들이기 위한 news
  let news = [];
  const { data, error } = useSWR('https://back.listella.tv/tweets', fetcher);

  if (error) return <div>!데이터 로드 실패!</div>;
  if (!data) return <div>데이터 불러오는 중 ...</div>;

  async function fetcher(url) {
    const res = await fetch(url);
    if (!res.ok) throw Error('Fetch 데이터 받기에 실패했습니다.');
    return res.json();
  }

  //news 배열에 제목, 포스팅 날짜, 주소 추가
  data.tweets.map((item, index) => {

    //트위터 포스팅 제목 위한 문자열 자르기 기능
    const sliceTitle = () => {
      var newsTitle = [];
      newsTitle[index] = data.tweets[index].tweetText;
      //html태그 제거
      const htmlToText = (html) => {
        const pattern = /<[^>]+>/g;
        return html.replace(pattern, '');
      };
      //html 태그 제거 후 줄넘김별로 배열처리
      newsTitle[index] = htmlToText(newsTitle[index]).split('\n')


      //@, #을 포함하거나, 공백인 것 제거
      newsTitle[index] = newsTitle[index].map((value) => {

        if (value.indexOf('@') >= 0 || value.indexOf('#') >= 0 || value === null || value.indexOf('https') >= 0) {
          return '  ';
        } else {
          //배열에 존재하는 HTML엔티티 교체
          value = value.replace('&amp;', "&")
            .replace('&lt;', "<")
            .replace('&gt;', ">")
            .replace('&quot;', "\"")
            .replace('&apos;', "'");

          return value;
        }
      }).filter(value => value !== null && value.trim() !== '');

      var result;
      //배열 사이에 공백 추가
      result = newsTitle.flatMap(arr => arr.join(' '));

      return result;
    }
    //'--년 --월 --일' 로 수정
    const editDate = () => {
      const date = new Date(data.tweets[index].createdAt);
      const year = date.getFullYear();
      const month = ('0' + (date.getMonth() + 1)).slice(-2);
      const day = ('0' + date.getDate()).slice(-2);
      return `${year}년 ${month}월 ${day}일`;
    }

    news[index] = {
      tweetText: sliceTitle(),
      publishedAt: editDate(),
      href: "https://twitter.com/listella_on/status/" + data.tweets[index].id,
      id: data.tweets[index].id
    }
  })

  const removeSubThread = () => {
    const idList = [];
    news.forEach((value, index) => {
      idList[index] = value.id;
    });

    const duplicateIndexes = idList.reduce((acc, value, index, arr) => {
      //mainTweet 과 mainTweet의 Thread Id값이 앞6자리가 같다는 것, 그리고 Thread의 index가 더 낮다는 것을 이용하였음
      const firstSixCharacters = value.substring(0, 6);
      const duplicateIndex = arr.findIndex(
        (item) => item.substring(0, 6) === firstSixCharacters
      );
      if (duplicateIndex !== index && !acc.includes(duplicateIndex)) {
        acc.push(duplicateIndex);
      }
      return acc;
    }, []);

    const filteredNews = news.filter((_, index) => !duplicateIndexes.includes(index));
    return filteredNews;
  };

  news = removeSubThread();

  const liMake = (item) => {
    return (
      <a
        href={item.href}
        target="_blank"
        rel="noreferrer"
        className={`borderBottom flex flex-row justify-between ${w < h * 100 ? 'py-1' : 'py-3'}`}
      >
        <p className="news-title">{item.tweetText}</p>
        <p className="news-date">{item.publishedAt}</p>
      </a>
    );
  }

  const twitString = news.map((item, index) => {
    return (
      <li key={index}>
        {liMake(item)}
      </li>
    )
  });

  return (
    twitString
  );
}


export function NewsSection(props) {
  const { setNav, w, h } = props;
  return (
    <Section id="2" onFocus={(id) => setNav(id)} src="./bg-section01.webp">
      <div className="hero-overlay bg-opacity-50 absolute none">
        <div className="section-content">
          <Animate variants={fadeText} delay={300}>
            <h3 className={`${w < h ? 'my-[6vh]' : 'my-[8vh]'}`}>소식</h3>
          </Animate>
          <Animate variants={fadeText} delay={600}>
            <div
              className={`flex justify-start max-w-[90vw] ${w < h ? 'w-[40vh] h-[62vh]  flex-col' : 'w-[120vh] h-[50vh] flex-row'
                }`}
              style={{ fontSize: '2vh', lineHeight: '4vh' }}
            >
              <div className={`flex ${w < h ? 'w-full h-[35%] borderWhite' : 'w-1/2 h-full'} `}>
                <Swiper
                  modules={[Pagination, Autoplay]}
                  pagination={{
                    dynamicBullets: true,
                  }}
                  loop={false}
                  autoplay={{ delay: 10000, pauseOnMouseEnter: true, disableOnInteraction: false }}
                  className="noticeSlider"
                >
                  <SwiperSlide>
                    <img src="aethe-qna.webp" alt="QNA" />
                  </SwiperSlide>
                </Swiper>
              </div>
              <div
                className={`flex flex-col ${w < h ? ' w-full h-[55%] py-[2vh]' : 'w-1/2 h-full blurBox p-[2vh]'
                  } `}
              >
                <div className="news-content relative text-left items-center w-full h-[90%]">
                  <ul className={`borderBottomBold flex ${w < h ? 'justify-center' : 'justify-start'}`}>
                    <li className="news-tab w-[8vh] h-full">최신</li>
                  </ul>
                  <ul className="news-list">

                    <Tweets w={w} h={h} setNav={setNav} />

                  </ul>
                </div>
                <div
                  className={`news-more smFont h-[5vh] py-1 mt-4 ${w < h ? 'w-auto justify-center' : 'text-right justify-end'
                    }`}
                >
                  <a
                    href="https://twitter.com/listella_on"
                    target="_blank"
                    rel="noreferrer"
                    className={`flex relative h-[5vh] py-1 mt-4 ${w < h ? 'w-full justify-center blurBox borderWhite' : 'w-auto justify-end'
                      }`}
                  >
                    <span className="flex px-[2vh]">더보기</span>
                  </a>
                </div>
              </div>
            </div>
          </Animate>
          <div className="absolute left-1/2 bottom-0 -translate-x-1/2 fadeIn fast">
            <img
              className="z-30 animate-bounce"
              src="./scroll-down.svg"
              alt="scroll-down"
              style={{ width: '8vh' }}
            />
          </div>
        </div>
      </div>
    </Section>
  );
}