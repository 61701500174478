const LoadingIcon = ({ size }) => (
  <svg
    version="1.1"
    baseProfile="basic"
    id="레이어_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    width={size}
    height={size}
    viewBox="0 0 300 300"
    xmlSpace="preserve"
  >
    <path
      fill="#FFFFFF"
      d="M123.2,123.3v32.3c0,0,19.8,2.7,26.5,10.2c0,0,8.7-7.7,27-10v-32.5c0,0-11.3-3.3-26.8,6.4
		C150,129.7,138.8,120.9,123.2,123.3z"
    />
    <path
      fill="#FFFFFF"
      d="M112.7,129.5h6.8v29.4c0,0,18.4,1.1,30.7,11.5c0,0,10.5-9.3,30.1-11.4v-29.5h7v35.6c0,0-18.3-1.8-37.3,12.2
		c0,0-14.7-12.5-37.3-12.3V129.5z"
    />
    <path
      fill="#FFFFFF"
      d="M150.1,221.4c-20.5,0-35.3-9.2-36.1-9.7c-2.3-1.5-3-4.6-1.6-6.9c1.5-2.3,4.6-3,6.9-1.6l0,0
 c0.2,0.1,19.5,12,43,6.8c2.7-0.6,5.4,1.1,6,3.8c0.6,2.7-1.1,5.4-3.8,6C159.5,220.9,154.6,221.4,150.1,221.4z"
    >
      <animateTransform
        attributeName="transform"
        type="rotate"
        values="0 150 150; 120 150 150; 360 150 150"
        attributeType="XML"
        dur="2s"
        repeatCount="indefinite"
      />
    </path>
  </svg>
);

export default LoadingIcon;
