import { useEffect, useState } from 'react';

const useRTEventCharactor = () => {
  const [loading, setLoading] = useState({
    TEST: false,
  });
  const [error, setError] = useState();

  const [charactor, setCharactor] = useState({
    ODANMING: {
      colors: {
        name: '#ff9e1f',
        nameBorder: '#fffccb',
      },
      imageURL: '/RT/Yato_RT_20.png',
      twitchLink: 'https://www.twitch.tv/listella_yato'
    },
    HAMKUBBY: {
      colors: {
        name: '#ff9e1f',
        nameBorder: '#fffccb',
      },
      imageURL: '/RT/Yato_RT_20.png',
      twitchLink: 'https://www.twitch.tv/listella_yato'
    },
    LOBOMOONLIT: {
      colors: {
        name: '#ff9e1f',
        nameBorder: '#fffccb',
      },
      imageURL: '/RT/Yato_RT_20.png',
      twitchLink: 'https://www.twitch.tv/listella_yato'
    },
  });

  const url = `https://back.listella.tv/retweet`;
  
  useEffect(() => {
    const charactors = { danming: 'ODANMING', kubby: 'HAMKUBBY', robo: 'LOBOMOONLIT' };

    Object.entries(charactors).map(async ([key, name]) => {
      if (!loading[name]) {
        await fetch(`${url}/${key}`, {
          method: 'GET',
        })
          .then((response) => response.json())
          .then((data) => {
            if (data) {
              setCharactor((charactors) => ({
                ...charactors,
                [name]: { ...charactors[name], ...data },
              }));
              setLoading((loadings) => ({ ...loadings, [name]: true }));
            }
          })
          .catch((error) => setError(error));
      }
    });
  }, [loading, url]);
  const allLoading = Object.values(loading).find((is) => is === true);

  return { charactor, loading, error };
};

export default useRTEventCharactor;
