const useCharactor = () => {
  const charactor = {
    YATO: {
      colors: {
        name: '#ff9e1f',
        nameBorder: '#fffccb',
      },
      imageURL: '/RT/Yato_RT_20-1.png',
      platformLink: 'https://chzzk.naver.com/e87999abca4fd0c3214e05ef414ce951',
    },
  };

  return { charactor };
};

export default useCharactor;
