import { useEffect } from 'react';
import { useInView } from 'react-intersection-observer';

export function Section({ id, children, src, onFocus }) {
  const [ref, inView] = useInView({ threshold: 0.5 });
  useEffect(() => {
    if (onFocus && inView) {
      onFocus(id);
    }
  }, [id, inView, onFocus]);

  return (
    <div
      id={id}
      ref={ref}
      className="relative h-screen w-screen bg-cover bg-center bg-no-repeat snap-center text-white"
      style={{ backgroundImage: `url("${src}")`, height: 'calc(var(--h, 1vh) * 100)' }}
    >
      {children}
    </div>
  );
}