import { Route, Routes } from 'react-router-dom';

import Cookies from './cookies/Cookies';

export default function LegalRouter() {
  return (
    <Routes>
      <Route path="/cookies" element={<Cookies />} />
    </Routes>
  );
}
