import React, { useEffect } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import smoothscroll from 'smoothscroll-polyfill';
import smoothscrollAnchorPolyfill from 'smoothscroll-anchor-polyfill';
import { SWRConfig } from 'swr';

import IntroRouter from './routes/intro';
import HomeRouter from './routes/home';
import LegalRouter from './routes/legal';
import RandomChannelRouter from './routes/random-channel'

function App() {
  useEffect(() => {
    smoothscroll.polyfill();
    smoothscrollAnchorPolyfill.polyfill();
  }, []);

  const swrOptions = {
    fetcher: (url) =>
      fetch(url).then(async (response) => {
        if (response.status !== 200) {
          const error = new Error('An error occurred while fetching the data.');
          error.info = await response.json();
          error.status = response.status;
          throw error;
        }
        return response.json();
      }),
  };

  return (
    <SWRConfig value={swrOptions}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Navigate replace to="/intro" />} />
          <Route path="/intro/*" element={<IntroRouter />} />
          <Route path="/home/*" element={<HomeRouter />} />
          <Route path="/legal/*" element={<LegalRouter />} />
          <Route path="/random-channel/*" element={<RandomChannelRouter />} />
        </Routes>
      </BrowserRouter>
    </SWRConfig>
  );
}

export default App;
