const CHANNELS = [
  'UCKwVBQK051ZNopXMBL1QFGQ',
  'UCycQh6KkWKhRs7rzE8s0DFw',
  'UChaT4DUnWxSsym-devGo6wQ',
];

function getChannelID(max) {
  const index = Math.floor(Math.random() * max);
  return CHANNELS[index];
}

export default function RandomChannelRouter() {
  const channel = getChannelID(3);
  const href = `https://www.youtube.com/channel/${channel}?sub_confirmation=1`;

  window.location.replace(href);
  return null;
}
