import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Navigation } from 'swiper';
import { fadeText, Animate } from '../../lib/home/utils';
import YouTube from 'react-youtube';
import { Section } from './Section';
import useSWR from 'swr';
import { useRef, useState, useEffect } from 'react';

export const MediaSection = ({ w, h, setNav }) => {
  const [playerWidth, setPlayerWidth] = useState(640);
  const [playerHeight, setPlayerHeight] = useState(360);
  const playerRef = useRef(null);

  const handleResize = () => {
    // 화면 크기에 따라 플레이어의 크기를 조절
    if (playerRef.current) {
      const containerWidth = playerRef.current.parentNode.offsetWidth * 0.7;
      const containerHeight = containerWidth * 0.5425;
      setPlayerWidth(containerWidth);
      setPlayerHeight(containerHeight);
    }
  };

  useEffect(() => {
    // 컴포넌트가 마운트될 때 resize 이벤트 등록
    window.addEventListener('resize', handleResize);
    handleResize();
    // 컴포넌트가 언마운트될 때 resize 이벤트 해제
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // url 요청
  const fetcher = async (url) => {
    const response = await fetch(url);
    const data = await response.json();
    return data;
  };

  // useSWR 훅을 사용하여 서버 데이터 가져오기
  const { data, error } = useSWR('https://back.listella.tv/movies', fetcher);
  if (error) return <div>failed to load</div>;
  if (!data) return <div>loading...</div>;

  // YouTube 영상 보여주기
  const videoShow = data.movies.map((item, index) => {
    return (
      <SwiperSlide key={index}>
        <YouTube
          style={{ userSelect: 'none', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
          videoId={item.id}
          className="w-full h-full"
          alt={'Scene' + index + 1}
          opts={{
            height: w < h ? `${w / 1.9558}` : '100%',
            width: w < h ? `${w / 1.1}` : '100%',
            playerVars: {
              rel: 0, //관련 동영상 표시
              modestbranding: 1, // 컨트롤 바에 youtube 로고를 표시하지 않음
            },
          }}
        />
      </SwiperSlide>
    );
  });
  return (
    <div>
      <Section id="5" onFocus={(id) => setNav(id)} src="./bg-section04.webp">
        <div className="hero-overlay bg-opacity-50 absolute none">
          <div className="section-content">
            <Animate variants={fadeText} delay={300}>
              <h3 className={`${w < h ? 'my-[6vh]' : 'my-[8vh]'}`}>미디어</h3>
            </Animate>
            <Animate variants={fadeText} delay={600}>
              <div
                className={`" ${w < 370 ? 'flex pt-[1.5vh] w-[50vh] lg:w-[100vh]' : w < h ? 'flex pt-[1.5vh] w-[70vh] lg:w-[100vh]' : 'flex pt-[1.5vh] w-[89vh] lg:w-[100vh]'}`}
                style={{ fontSize: '2vh', lineHeight: '4vh' }}
                ref={playerRef}
              >
                <Swiper
                  modules={[Pagination, Navigation]}
                  pagination={{
                    dynamicBullets: true,
                  }}
                  navigation={{
                    enabled: true,
                  }}
                  className="illustSlider"
                  style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                >
                  {videoShow}
                </Swiper>
              </div>
            </Animate>
          </div>
        </div>
      </Section>
    </div>
  );
};
